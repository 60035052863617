import {skipToken} from '@reduxjs/toolkit/query';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';

import {questTypesNames} from 'modules/events/types';
import {WidgetContainers} from 'modules/widgets/utils';
import {widgetsColumns} from 'modules/widgets/columns';
import {WidgetAttributesList, WidgetForm} from 'modules/widgets/containers';

import {useConfirmDialog} from 'components/Dialog';
import {useDialogSelector} from 'components/DialogSelector';

import {QuestPointRewardsProps} from './types';

export const QuestPointWidgets = ({pointId}: QuestPointRewardsProps) => {
  const [patch] = adminApi.endpoints.questPointPatch.useMutation();
  const {questId, options, visualsId, widgetId} = adminApi.endpoints.questPointIndex.useQuery(
    {pointId},
    {
      selectFromResult: ({data}) => ({
        questId: data?.questId,
        options: data?.options,
        widgetId: data?.options.widgets?.widgetId || undefined,
        visualsId: data?.options.widgets?.visualsId || [],
      }),
    }
  );
  const {data: widget} = adminApi.endpoints.widgetIndex.useQuery(widgetId ? {widgetId} : skipToken);
  const {data: quest} = adminApi.endpoints.questIndex.useQuery(questId ? {questId} : skipToken);
  const {tasksId} = adminApi.endpoints.questPointsIndex.useQuery(questId ? {questId} : skipToken, {
    selectFromResult: ({data = []}) => ({
      tasksId: data
        .filter(item => !item.deletedAt && item.enabled)
        .map(questPoint => questPoint.options.tasks?.taskId)
        .filter(Boolean) as string[],
    }),
  });
  const [reassembleWidget] = adminApi.endpoints.widgetReassemble.useMutation();
  const [createWidgetForTasks] = adminApi.endpoints.widgetsAssemble.useMutation();

  const createWidget = async () => {
    if (!quest || !options) return;
    const result = await createWidgetForTasks({
      assembleWidgetDto: {
        taskId: tasksId,
        name: `${questTypesNames[quest.type]} – ${quest.name}`,
        containerName: WidgetContainers.PUBLICATION,
      },
    });
    if ('data' in result) {
      const widget = result.data;
      await patch({
        pointId,
        questPointPartialDto: {options: {...options, widgets: {visualsId, widgetId: widget._id!}}},
      });
    }
  };

  const onReassembleWidget = async () => {
    if (!widgetId) return;
    await reassembleWidget({
      widgetId,
      assembleWidgetDto: {
        taskId: tasksId,
      },
    });
  };

  const setWidget = (widgetId?: string) => {
    if (options) patch({pointId, questPointPartialDto: {options: {...options, widgets: {visualsId, widgetId}}}});
  };
  const [WidgetSelector, openSelectorDialog] = useDialogSelector();
  const [ConfirmationDialog, removeWidget] = useConfirmDialog({onConfirm: () => setWidget()});

  return (
    <>
      <ConfirmationDialog
        title="Удалить виджет?"
        text="В таком случае квест-поинт не будет собирать данные из заданий, которые указаны в виджете"
      />
      <WidgetSelector
        single
        params={{}}
        initialSelection={[]}
        queryName="widgetsIndex"
        title="Выбор виджета"
        columns={widgetsColumns()}
        onConfirm={([entryId]) => setWidget(entryId)}
      />
      <Stack spacing={2}>
        {!widgetId && (
          <>
            <Alert severity="info">
              Виджеты – это инструмент сбора и вывода введенных пользователем данных в различных местах приложения и
              административной панели. Для того, чтобы виджет наполнялся данными, он должен быть привязан к квесту, в
              рамках которого пользователи вводят те или иные данные.
            </Alert>
            <Alert severity="info" action={<Button onClick={createWidget}>Создать&nbsp;виджет</Button>}>
              При клике на кнопку создания виджета, будет создан новый виджет, который будет содержать в себе все ответы
              на задания, которые находятся в текущем квесте. После создания виджета можно будет изменить состав его
              атрибутов.
            </Alert>
            <Alert severity="info" action={<Button onClick={openSelectorDialog}>Выбрать&nbsp;виджет</Button>}>
              Можно выбрать виджет из ранее созданных. В данном случае нужно иметь в виду, что изменение состава
              стрибутов виджета может затронуть другие квесты / публикации
            </Alert>
          </>
        )}

        {widgetId && (
          <>
            <Alert severity="warning" action={<Button onClick={removeWidget}>Удалить</Button>}>
              Удаляя виджет из этого квест-поинта, он не удаляется из всей системы. При этом, если виджет не прикреплен
              к квест-поинту, он не будет автоматически собирать данные.
            </Alert>
            {widget && widget.isAssembled && (
              <Alert severity="info" action={<Button onClick={onReassembleWidget}>Пересобрать</Button>}>
                При изменении состава заданий в квесте, виджет не будет пересобран автоматически. Чтобы пересобрать
                виджет, нажмите действие
              </Alert>
            )}
            <Card>
              <CardContent>
                <WidgetForm widgetId={widgetId} />
              </CardContent>
            </Card>
            <WidgetAttributesList widgetId={widgetId} />
          </>
        )}
      </Stack>
    </>
  );
};
