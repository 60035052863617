import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';
import {useDialog} from 'components/Dialog';
import {RewardForm} from 'modules/tasks/containers/RewardForm';

import {QuestPointRewardsProps} from './types';
import {QuestPointRewardForm} from './QuestPointRewardForm';

export const QuestPointRewards = ({pointId}: QuestPointRewardsProps) => {
  const [addReward] = adminApi.endpoints.questPointsRewardsAdd.useMutation();
  const {data: questPointRewards = []} = adminApi.endpoints.questPointsRewardsIndex.useQuery({pointId, enabled: true});
  const [Dialog, openDialog, closeDialog] = useDialog();

  return (
    <>
      <Dialog title="Создать награду">
        <RewardForm
          onCreate={async reward => {
            await addReward({
              pointId,
              questPointRewardDto: {rewardValue: {rewardId: reward._id!, value: 1}, enabled: true},
            });
            closeDialog();
          }}
        />
      </Dialog>
      <Alert
        action={
          <Button onClick={openDialog} sx={{whiteSpace: 'nowrap'}}>
            Создать награду
          </Button>
        }
      >
        Вы можете создать новую награду и сразу привязать ее к текущей точке квеста, нажав на кнопку{' '}
        <strong>Создать награду</strong>. Если награда уже существует в системе, воспользуейтесь формой добавления
        награды в точку квеста, которая располагается ниже
      </Alert>

      <Stack spacing={2}>
        <Card>
          <CardHeader title="Добавить награду" />
          <CardContent>
            <QuestPointRewardForm pointId={pointId} />
          </CardContent>
        </Card>
      </Stack>
      {questPointRewards.map(i => (
        <Card key={i._id}>
          <CardContent>
            <QuestPointRewardForm pointId={pointId} questPointReward={i} />
          </CardContent>
        </Card>
      ))}
    </>
  );
};
