import {pick} from 'lodash';
import {adminApi} from 'api';
import {useCallback} from 'react';

import {RewardDto, Rewards} from '@apiSchema/admin-api';
import {initialValues} from './fields';

export const useCreateReward = (data?: Rewards, onCreate?: (data: Rewards) => void) => {
  const [update] = adminApi.endpoints.rewardUpdate.useMutation();
  const [create] = adminApi.endpoints.rewardsAdd.useMutation();
  const onSubmit = useCallback(
    async (inputData: RewardDto) => {
      if (data && data._id) return update({rewardId: data._id, rewardDto: inputData});
      else
        create({rewardDto: inputData}).then(res => {
          if ('data' in res && res.data) onCreate?.(res.data);
        });
    },
    [data, update, create, onCreate]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as RewardDto) : initialValues;

  return {initials, onSubmit};
};

export default useCreateReward;
